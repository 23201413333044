import { del, get, patch, post } from './http_client'
import {
  groups_path,
  group_path,
  post_path,
  join_group_path,
  leave_group_path, query_groups_path
} from '../routes'

export const create = async params => post({ url: groups_path(), params: { group: params } });
export const getById = async (id, params) => get({ url: group_path({ id: id }), params })
export const index = async (params = {}) => get({ url: groups_path(), params })
export const join = async ({ id }) => post({ url: join_group_path({ id })})
export const leave = async ({ id }) => del({ url: leave_group_path({ id })})
export const update = async (group, params) => patch({ url: group_path({ id: group.id }), params: { group: params } });
export const query = async (params) => get({ url: query_groups_path(params) })
