import {
  all_posts_path,
  posts_path,
  post_path,
  undo_destroy_post_path, query_posts_path, public_square_posts_path, feed_posts_path
} from '../routes'
import { get, patch, post, del } from './http_client';
import { cache } from './cache';

const generateCacheKey = (p) => {
  const { type, id } = p
  return `${type}-${id}`;
}
const setCacheAndReturn = p => {
  cache.set(generateCacheKey(p), p);
  return p;
}

export const destroy = async (post) => {
  return del({ url: post_path({ id: post.id }) }).
    then(() => cache.invalidate(generateCacheKey(post)))
}
export const undo_destroy = async (post) => {
  patch({ url: undo_destroy_post_path({ id: post.id }) }).then(setCacheAndReturn)
}
export const all = async () => get({ url: all_posts_path() })
export const getById = async (id, params) => {
  const key = `Sozi::Post-${id}`
  if (cache.hasKey(key)) return cache.get(key)
  else return get({ url: post_path({ id: id }), params }).then(setCacheAndReturn)
}
export const create = async params => {
  return post({ url: posts_path(), params: { post: params } }).then(setCacheAndReturn);
}
export const update = async (post, params) => {
  return patch({ url: post_path({ id: post.id }), params: { post: params } }).then(setCacheAndReturn);
}
export const query = async(params) => get({ url: query_posts_path(params) })
export const public_square = async (params) => get({ url: public_square_posts_path(params) })
export const feed = async (params) => get({ url: feed_posts_path(params) })
