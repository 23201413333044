import * as My from './my_api';
import * as MyAccount from './my/account_api';
import * as MyPassword from './my/password_api';
import * as MyProfile from './my/profile_api';
import * as MySettings from './my/settings_api';
import * as Posts from './posts_api';
import * as Reactions from './reactions_api';
import * as UserRegistrations from './users/registrations_api';
import * as UserSessions from './users/sessions_api';
import * as ReadReceipts from './read_receipts_api';
import * as Groups from './groups_api';
import * as Forums from './forums_api';
import * as Accounts from './accounts_api'
import * as Users from './users_api'
import * as Settings from './settings_api';
import * as Files from './files_api';

export {
  Accounts,
  Files,
  Forums,
  Groups,
  My,
  MyAccount,
  MyPassword,
  MyProfile,
  MySettings,
  Posts,
  ReadReceipts,
  Reactions,
  Settings,
  UserRegistrations,
  Users,
  UserSessions
}
