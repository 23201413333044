
const convertFormDataToObject = async formData => {
  const payload = {};
  for (let [key, value] of formData.entries()) {
    let val = value;
    if (value instanceof File) {
      const data = await toBase64(value);
      if (data !== 'data:') val = { filename: value.name, io: await data, content_type: value.type };
    }
    if (Reflect.has(payload, key)) {
      if (!Array.isArray(payload[key])) payload[key] = [payload[key]];
      payload[key].push(val)
    } else payload[key] = val;
  }

  return payload;
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export class UnauthenticatedError extends Error {
  constructor(message) {
    super(message);
    this.name = "UnauthenticatedError";
  }
}

const convertParams = async (params) => {
  let payload = {};
  for (let [key, value] of Object.entries(params)) {
    if (value instanceof FormData) {
      payload[key] = await convertFormDataToObject(value);
    }
    else {
      payload[key] = value;
    }
  }

  return payload;
}

const convertNestedParams = (params) => {
  const acc = []
  if (Array.isArray(params)) {
    acc.push(params.map(v => `[]=${v}`).join(''))
  }
  else if (typeof params === 'object') {
    acc.push(Object.keys(params).map(k => {
      return `[${k}]=${params[k]}`
    }).join('&'))
  }
  else acc.push(params)
  return acc.join('&')
}

const queryStringFromParams = (params) => {
  const acc = []
  for (let [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      acc.push(convertNestedParams(key, value))
    }
    else if (typeof value === 'object') {
      acc.push(Object.keys(value).map(k => {
        return `${key}[${k}]=` + convertNestedParams(value[k])
      }).join('&'))
    }
    else acc.push(`${key}=${value}`)
  }
  return acc.join('&')
}

const request = async ({ method, url, params = {} } ) => {
  const payload = await convertParams(params)

  const headers = new Headers();
  headers.append('accept', 'application/json; charset=utf-8');
  headers.append('content-type', 'application/json; charset=utf-8');

  const options = {}
  if (['POST', 'PATCH', 'PUT'].includes(method)) {
    options.body = Object.keys(payload).length === 0 ? null : JSON.stringify(payload);
  }

  let formattedURL = url;
  if (method === 'GET' && typeof params === 'object' && !/\?/.test(url)) {
    formattedURL = url + '?' + queryStringFromParams(params)
  }

  return fetch(
    formattedURL,
    {
      credentials: 'same-origin',
      method,
      headers,
      ...options
    }).
      then(r => {
        if ([401, 403].includes(r.status)) {
          throw new UnauthenticatedError();
        }
        else if (r.status === 204) {
          return null;
        }
        else {
          return r.json();
        }
      })
  }

export const del = async ({ url, params }) => request({ method: 'DELETE', url, params});
export const get = async ({ url, params }) => request({ method: 'GET', url, params});
export const patch = async ({ url, params }) => request({ method: 'PATCH', url, params});
export const post = async ({ url, params }) => request({ method: 'POST', url, params});
