import { del, get, patch, post } from './http_client'
import {
  account_path, follow_account_path, group_path,
  posts_account_path,
  unfollow_account_path
} from '../routes'

export const posts = user => get({ url: posts_account_path({ id: user.id }) });
export const show = id => get({ url: account_path({ id }) });
export const follow = id => post({ url: follow_account_path({ id })})
export const unfollow = id => del({ url: unfollow_account_path({ id })})
export const update = async (account, params) => patch({ url: account_path({ id: account.id }), params: { account: params } });
