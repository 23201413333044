import {
  users_sessions_path,
  refresh_users_sessions_path,
  destroy_users_sessions_path
} from "../../routes";
import { del, get, post } from '../http_client';

export const create = async ({email, password}) => post({url: users_sessions_path(), params: { user: { email, password } }});
export const destroy = async () => del({ url: destroy_users_sessions_path() });
export const refresh = async () => get({ url: refresh_users_sessions_path(), params: {} })
