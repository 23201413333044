import { del, get, patch, post } from './http_client'
import {
  forums_path,
  forum_path,
  post_path,
  query_forums_path,
  follow_account_path,
  unfollow_account_path,
  follow_forum_path,
  unfollow_forum_path
} from '../routes'

export const create = async params => post({ url: forums_path(), params: { forum: params } });
export const index = async (params = {}) => get({ url: forums_path(), params })
export const update = async (forum, params) => patch({ url: forum_path({ id: forum.id }), params: { forum: params } });
export const query = async (params) => get({ url: query_forums_path(params) })
export const destroy = async (forum) => del({ url: forum_path(forum.id) })
export const follow = id => post({ url: follow_forum_path({ id })})
export const unfollow = id => del({ url: unfollow_forum_path({ id })})
