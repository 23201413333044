import { del, get, patch, post } from './http_client'
import {
  files_path,
  file_path,
  query_files_path
} from '../routes'

export const create = async params => post({ url: files_path(), params: { file: params } });
export const getById = async (id, params) => get({ url: file_path({ id: id }), params })
export const index = async (params = {}) => get({ url: files_path(), params })
export const update = async (file, params) => patch({ url: file_path({ id: file.id }), params: { file: params } });
export const query = async (params) => get({ url: query_files_path(params) })
