export class Cache {
  store = {};

  clear() {
    this.store = {}
  }

  set(key, resource) {
    this.store[key] = resource;
  }

  get(key) {
    const result = this.store[key];
    return result
  }

  hasKey(key) {
    return this.store.hasOwnProperty(key)
  }

  invalidate(key) {
    delete this.store[key];
  }
}

const cache = new Cache()
window.soziCache = cache
export { cache }
